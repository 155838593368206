/**
 * 
 * @author Ensemble
 * @version 1.0
 */


 ;(function ($) {
    'use strict';
    class requestHandler {
      constructor(url, method="GET", data=null, redirect=false) {
          this.url = url;
          this.method = method;
          this.data = data;
          if (this.data){
              this.data = JSON.stringify(this.data);
          };
          this.redirect = redirect;
          return this.run()
      }
      async run() {
        if (window.fetch) {
          let objParams = {
              method: this.method,
              redirect: "error",
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json"
              },
              body: this.data
          };
          if (this.redirect) {objParams.redirect = 'follow'};
          switch (this.method) {
              case "GET":
              case "POST":
              break;
              case "PUT":
              case "DELETE":
                  objParams.method = "POST";
                  this.url += `/?_method=${this.method}`;
          }
          try {
              return await fetch(this.url, objParams);
          } catch (error) {
              alert(error);
          }
        }
      }
    };
    const theme = {
  
      /**
       * Theme's components/functions list
       * Comment out or delete the unnecessary component.
       * Some component have dependencies (plugins). Do not forget to remove dependency.
      */
  
      init: () => {
        // theme.passwordVisibilityToggle();
        // theme.fileDropArea();
        // theme.multilevelDropdown();
        theme.conversionsAnalytics();
        theme.formContact();
        theme.smoothScroll();
        theme.scrollTopButton();
        theme.querySearch();
        theme.validatePatternOfTextarea();
        theme.replaceStropheInInputsAndTextarea();
        theme.liveCharacterCounter();
      },
  
      // /**
      //  * Toggling password visibility in password input
      //  * @memberof theme
      //  * @method passwordVisibilityToggle
      // */
      // Mettre mon code
  
  
      // /**
      //  * Custom file drag and drop area
      //  * @memberof theme
      //  * @method fileDropArea
      // */
      // fileDropArea: () => {
  
      //   let fileArea = document.querySelectorAll('.cs-file-drop-area');
  
      //   for (let i = 0; i < fileArea.length; i++) {
      //     let input = fileArea[i].querySelector('.cs-file-drop-input'),
      //         message = fileArea[i].querySelector('.cs-file-drop-message'),
      //         icon = fileArea[i].querySelector('.cs-file-drop-icon'),
      //         button = fileArea[i].querySelector('.cs-file-drop-btn');
          
      //     button.addEventListener('click', function() {
      //       input.click();
      //     });
  
      //     input.addEventListener('change', function() {
      //       if (input.files && input.files[0]) {
      //         let reader = new FileReader();
      //         reader.onload = (e) => {
      //           let fileData = e.target.result;
      //           let fileName = input.files[0].name;
      //           message.innerHTML = fileName;
  
      //           if (fileData.startsWith('data:image')) {
  
      //             let image = new Image();
      //             image.src = fileData;
  
      //             image.onload = function() {
      //               icon.className = 'cs-file-drop-preview';
      //               icon.innerHTML = '<img class="img-thumbnail rounded" src="' + image.src + '" alt="' + fileName + '">';
      //             }
  
      //           } else if (fileData.startsWith('data:video')) {
      //             icon.innerHTML = '';
      //             icon.className = '';
      //             icon.className = 'cs-file-drop-icon cxi-video';
  
      //           } else {
      //             icon.innerHTML = '';
      //             icon.className = '';
      //             icon.className = 'cs-file-drop-icon cxi-files';
      //           }
      //         }
      //         reader.readAsDataURL(input.files[0]);
      //       }
  
      //     });
      //   }
      // },
  
      // /**
      //  * Multilevel dropdown
      //  * @memberof theme
      //  * @method multilevelDropdown
      //  * @requires https://jquery.com/
      //  * @requires https://getbootstrap.com
      // */
      // multilevelDropdown: function () {
  
      //   let selector = ".dropdown-menu [data-bs-toggle='dropdown']";
  
      //   $(selector).on('click', function (e) {
      //     e.preventDefault();
      //     e.stopPropagation();
  
      //     $(this).siblings().toggleClass('show');
  
      //     if (!$(this).next().hasClass('show')) {
      //       $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
      //     }
      //     $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function () {
      //       $('.dropdown-submenu .show').removeClass('show');
      //     });
      //   });
      // },
      conversionsAnalytics: () => {
        $('.conversion-phone').on("click", function(event) {
          gtag('event', 'phone_link', {});
        });

        $('.conversion-mail').on("click", function(event) {
          gtag('event', 'mail_link', {});
        });
      },

      formContact: () => {
        if ($('#contact-form').length === 0 ) return;
        $("form#contact-form").on("submit", async function(event) {
            event.preventDefault();
            if (document.getElementById("contact-form").checkValidity() == true) {
                let data = {
                    _csrf: $('#csrf').val(),
                    formName: $('#formName').val(),
                    formContact: $('#formContact').val(),
                    formObject: $('#formObject').val(),
                    formMessage: $('#formMessage').val(),
                    formSubscribe: $('#formSubscribe').val()
                };
                try {
                    const fetchRequest = await new requestHandler("/message-envoye","POST",data,true);
                    if (fetchRequest) {
                        var res = await fetchRequest.json().then(function(responce) {return responce;});
                        if ( fetchRequest.status == 201 ){
                          $('form#contact-form').trigger("reset");
                          gtag('event', 'form_send', {
                            'form_name': 'contact'
                          });
                        };
                        return alert(res.message);
                    };
                } catch (e) {
                    // alert(e);
                    alert("Une erreur a eu lieu, veuillez réactualiser la page puis réessayer. Si le problème persiste, contacter le support. Err: 0x0017")
                }
            } else {
              $("form#contact-form").removeClass('needs-validation');
              $("form#contact-form").addClass('was-validated');
          };
            return false
        })

      },

      /**
       * Anchor smooth scrolling
       * @memberof theme
       * @method smoothScroll
       * @requires https://github.com/cferdinandi/smooth-scroll/
      */
      smoothScroll: () => {
        let selector = '[data-scroll]';
        if (!typeof selector =='undefined') {
          let fixedHeader = '[data-scroll-header]',
          scroll = new SmoothScroll(selector, {
            speed: 700,
            speedAsDuration: true,
            offset: 40,
            header: fixedHeader,
            updateURL: false
          });
        }
      },
      
      
      /**
       * Animate scroll to top button in/off view
       * @memberof theme
       * @method scrollTopButton
      */
      scrollTopButton: () => {
  
        let element = document.querySelector('.btn-scroll-top'),
            scrollOffset = 600;
  
        if (element == null) return;
  
        let offsetFromTop = parseInt(scrollOffset, 10);
  
        window.addEventListener('scroll', (e) => {
          if (e.currentTarget.pageYOffset > offsetFromTop) {
            element.classList.add('show');
          } else {
            element.classList.remove('show');
          }
        });
      },

      querySearch: () => {
        var btnSearch = document.querySelector('.btn-search');
        var inputSearch = document.querySelector('.input-search');

        function launchSearch() {
            var searchUrl = `/recherche?q=${inputSearch.value}`;
            var url = `${window.location.pathname}${window.location.search}`;
            // Si l'input Search n'est pas vide
            if (inputSearch.value) {
                // si l'url de la page n'est pas égale à l'url de recherche
                if ( url != searchUrl ) {
                    // redirige la page vers l'url de la recherche
                    window.location.href = searchUrl;
                };
            // Sinon si l'input search est vide et que la page actuel n'est pas /recherche
            } else if (url != "/recherche") {
                // Redirige vers la page /recherche
                window.location.href = "/recherche";
            };
        };

        if (inputSearch == null) return;
        inputSearch.addEventListener("keydown", function(e) {
            if ("Enter" === e.key) {
                launchSearch();
            }
        });
        inputSearch.addEventListener("input", function(e) {
          var sanitizedText = e.target.value.replace(/\'/g, "’").replace(/[^A-Za-z0-9!? \-’ÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸÆŒàâäéèêëïîôöùûüÿçæœ]/g, "");
          e.target.value = sanitizedText
        });

        if ( btnSearch == null) return;
        btnSearch.addEventListener("click", launchSearch);
      },
    
      validatePatternOfTextarea: () => {
          if ($("textarea").length === 0) return;
          $("textarea").on("input change propertychange", function() {
              var errorMsg = "Veuillez correspondre au format demandé.";
              var pattern = $(this).attr("pattern");
              if (typeof pattern == undefined || pattern == null) return;
              pattern = new RegExp("^" + pattern.replace(/^\^|\$$/g, "") + "$","g");
      
              // Vérifier chaque ligne de texte
              // $.each($(this).val().split("\n"), function () {
                  // Vérifier si la ligne correspond au motif
                  var hasError = !$(this).val().match(pattern);
                  // ===
                  if(typeof this.setCustomValidity == "function") {
                      this.setCustomValidity(hasError ? errorMsg : "")
      
                  } else {
                      // Non supporté par le navigateur, retour à l'affichage manuel des erreurs...
                      $(this).toggleClass("error", !!hasError);
                      $(this).toggleClass("ok", !hasError);
      
                      if (hasError) {
                          $(this).attr("title", errorMsg)
                      } else {
                          $(this).removeAttr("title")
                      };
                  };
                  // return !hasError;
              // });
          });
      },

      replaceStropheInInputsAndTextarea: () => {
        if ($("textarea, input[type=text]").length === 0) return;

          $("textarea, input[type=text]").on("input", function(e) {
              var val = e.target.value;
              e.target.value = val.replace(/\'/g, "’")
              // val.replace(/'/g, "\u2019")
          });
      },

      liveCharacterCounter: () => {
          document.addEventListener("DOMContentLoaded", function() {
              var inputs = document.getElementsByClassName("with-live-counter");
              if (inputs.length === 0) return;

              for (var i = 0; i < inputs.length; i++) {
                  inputs[i].addEventListener("keyup", function() {
                      var minLength = this.minLength;
                      var maxLength = this.maxLength;
                      var length = this.value.length;
                      var span = this.parentNode.querySelector(".count-span") || this.closest(".form-floating").parentNode.querySelector(".count-span");
                      span.textContent = length + " / " + maxLength;
                      if (length < minLength || length > maxLength ) {
                          span.className = "count-span ms-auto text-danger";
                      } else {
                          span.className = "count-span ms-auto";
                      };
                  });
              };
          });
      }

    }
  
    /**
     * Init theme core
    */
    
    theme.init();
  
  })(jQuery);
  